<template>
  <div>
    <b-tabs
      v-model="tabIndex"
    >
      <b-tab
        :title="$t('Detailed')"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Detailed") }}</strong>
        </template>
        <div
          class="filtering"
        >
          <div class="search-toggle">

            <p>{{ $t('SearchBy') }}</p>
            <div>
              <p
                :class="{ active2: toggleSarch === 'OrderNumber' }"
                @click="showOrderNumber(), toggleSarch = 'OrderNumber'"
              >
                {{ $t('OrderNumber') }}
              </p>
              <p
                :class="{ active2: toggleSarch === 'CustomerName' }"
                @click="showCustomerName(), toggleSarch = 'CustomerName'"
              >
                {{ $t('CustomerName') }}
              </p>
            </div>
          </div>
          <div v-if="toggleSarch === 'OrderNumber'">
            <div class="black">
              <b-icon-search
                v-if="searchON == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 13px"
              />
              <b-icon-x-circle
                v-else-if="searchON != '' && !noSearch"
                class="search1"
                style="position: absolute; left: 200px; top: 14px; cursor: pointer"
                @click="searchON = ''"
              />
              <input
                v-model="searchON"
                type="text"
                name="search-box"
                :placeholder="$t('OrderNumber')"
                :style="getFilteredOrdersbyON.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"

                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getFilteredOrdersbyON.length > 0 && noSearch"
              ref="scrollContainer"
              class="dropdrop"
              style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;"
              :style="getFilteredOrdersbyON.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredOrdersbyON"
                :key="index"
                ref="options"
                :class="{ 'is-active': index === arrowCounter }"
                style="cursor: pointer"
                class="search-item"
                @click="searchByOn(result); noSearch = false"
              >
                <p style="margin: 0">
                  {{ result }}
                </p>
              </div>
              <div
                v-if="getFilteredOrdersbyON.length == 0"
                class="search-item"
              >
                <p>{{ $t('NoResult') }}</p>
              </div>
            </div>
          </div>
          <div v-if="toggleSarch === 'CustomerName'">
            <div class="black">
              <b-icon-search
                v-if="searchCN == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 13px"
              />
              <b-icon-x-circle
                v-else-if="searchCN != '' && !noSearchTwo"
                class="search1"
                style="position: absolute; left: 200px; top: 14px; cursor: pointer"
                @click="searchCN = ''"
              />
              <div>
                <input
                  v-model="searchCN"
                  type="text"
                  autocomplete="off"
                  name="search-box"
                  :placeholder="$t('CustomerName')"
                  :style="getFilteredOrdersbyCN.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
                  @keyup="inputChanged2"
                  @keydown.down="onArrowDown2"
                  @keydown.up="onArrowUp2"
                >
              </div>
              <div
                v-if="getFilteredOrdersbyCN.length > 0 && noSearchTwo"
                ref="scrollContainer2"
                class="dropdrop"
                style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;
            "
                :style="getFilteredOrdersbyCN.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFilteredOrdersbyCN"
                  ref="options2"
                  :key="index"
                  :class="{ 'is-active': index === arrowCounter3 }"
                  style="cursor: pointer"
                  class="search-item"
                  @click="searchByCn(result); noSearchTwo = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
                <div
                  v-if="getFilteredOrdersbyCN.length == 0"
                  class="search-item"
                >
                  <p>{{ $t('NoResult') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inputs">
          <b-form-group :label="$t('StartDate')">
            <date-picker
              v-model="from"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
            />
          </b-form-group>
          <b-form-group :label="$t('EndDate')">
            <date-picker
              v-model="to"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
            />
          </b-form-group>
          <PdfDocument
            :method-name="'materialsSellingReportPDF'"
            :getter-name="'getMaterialSellingReportPDF'"
            :pdf-name="'SellingReport'"
            :is-pagination="true"
            :header-name="header"
            :object-value="objectPDF"
            :start-date="from"
            :end-date="to"
            :pdf-title="$t('SellingReport')"
            style="margin-top: 1.3%;"
          />
          <button
            v-b-tooltip.hover="$t('DownloadExcel')"
            style="border: 0; width: 48px; height: 37px; background: #ff274f; border-radius: 5px; margin-top: 1.3%;"
            @click="downloadEXCEL"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-file-earmark-spreadsheet"
              viewBox="0 0 16 16"
            >
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z"
              />
            </svg>
          </button>
        </div>
        <div style="height: 78vh; overflow-y: scroll">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead style="position: sticky; top: -2px">
              <tr>
                <th>
                  {{ $t("No") }}
                </th>
                <th>
                  {{ $t("OrderNumber") }}
                </th>
                <th>
                  {{ $t("ClientName") }}
                </th>
                <th>
                  {{ $t("OrderType") }}
                </th>
                <th>
                  {{ $t("VariantName") }}
                </th>
                <th>
                  {{ $t("Unit") }}
                </th>
                <th>
                  {{ $t("Quantity") }}
                </th>
                <th>
                  {{ $t("PricePerUnit") }}
                </th>
                <th>
                  {{ $t("PricePerMeterWithDiscount") }}
                </th>
                <th>
                  {{ $t("Total") }}
                </th>
              </tr>
            </thead>
            <tbody
              v-for="(card, index) in getMaterialSellingReport"
              :key="index"
            >
              <tr
                v-for="(item, index2) in card.materialVariantInStoreDtos"
                :key="index2"
              >
                <td>
                  {{ getMaterialSellingTotalItems - (pageNumber - 1) * pageSize - index }}.
                </td>
                <td>{{ card.orderNumber }}</td>
                <td>{{ card.clientFullName }}</td>
                <td>{{ $t(card.orderType) }}</td>
                <td>{{ item.name }}</td>
                <td>{{ $t(item.measurementType) }}</td>
                <td v-if="item.length > 0">
                  {{ item.length }}
                </td>
                <td v-else>
                  {{ item.quantity }}
                </td>
                <td>{{ numbersWithcomma(item.pricePerMeter) }}</td>
                <td>{{ numbersWithcomma(item.pricePerMeterWithDiscount) }}</td>
                <td v-if="item.length > 0">
                  {{
                    (item.pricePerMeterWithDiscount > 0 && item.pricePerMeterWithDiscount !== undefined)
                      ? item.length * item.pricePerMeterWithDiscount
                      : item.length * item.pricePerMeter
                  }}
                </td>
                <td v-else>
                  {{
                    (item.pricePerMeterWithDiscount > 0 && item.pricePerMeterWithDiscount !== undefined)
                      ? item.quantity * item.pricePerMeterWithDiscount
                      : item.quantity * item.pricePerMeter
                  }}
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="getMaterialSellingTotalItems > 15">
          <b-pagination
            v-model="pageNumber"
            :total-rows="getMaterialSellingTotalItems"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            prev-class="prev-itemi"
            next-class="next-itemi"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t("Previous") }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t("Next") }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </b-tab>
      <b-tab
        :title="$t('Grouped')"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Grouped") }}</strong>
        </template>
        <div>
          <SellingReportMaterialGrouped />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment';
// import jsPDF from 'jspdf';
import SellingReportMaterialGrouped from '@/components/materialsNominvative/SellingReportMaterialGrouped.vue'
import 'jspdf-autotable';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { mapActions, mapGetters } from 'vuex';
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'
import moment from 'moment'
import { client } from '../../domainConfig'

export default {
  components: {
    DatePicker,
    PdfDocument,
    SellingReportMaterialGrouped,
  },
  data() {
    return {
      clientId: null,
      toggleSarch: 'OrderNumber',
      searchShip: '',
      searchCN: '',
      onSearchTwo: false,
      searchON: '',
      arrowCounter: 0,
      arrowCounter2: 0,
      arrowCounter3: 0,
      noSearch: false,
      noSearchTwo: false,
      noSearchThree: false,
      tabIndex: null,
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      pageNumber: 1,
      pageSize: 15,
      client: client.clientName,
      header: [
        {
          orderNumber: this.$t('OrderNumber'),
          orderType: this.$t('OrderType'),
          materialVariantInStoreDtos: [
            { name: this.$t('MaterialName') },
            { measurementType: this.$t('MeasurementType') },
            { length: this.$t('Length') },
            { quantity: this.$t('Quantity') },
            { pricePerMeter: this.$t('PricePerUnit') },
            { pricePerMeterWithDiscount: this.$t('PricePerMeterWithDiscount') },
          ],
          totalPriceUnit: this.$t('TotalPrice'),
        },
      ],
      // objectPDF: {
      //   startDate: null,
      //   endDate: null,
      //   pageNumber: this.pageNumber,
      //   pageSize: this.pageSize,
      // },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getMaterialSellingReport',
      'getMaterialSellingTotalItems',
      'getLoggedInUser',
      'getFilteredOrdersbyON',
      'getFilteredOrdersbyCN',
      'getFilteredOrdersByShipment',
      'getMaterialSellingReportPDF',
    ]),
    objectPDF() {
      return {
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: 28,
      }
    },
  },
  watch: {
    searchON(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.fshij();
          this.materialSellingReport({
            startDate: this.from,
            endDate: this.to,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            clientId: null,
            orderNumber: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByOrderNumber({ orderNumber: value, salesType: 'Material' });
          this.noSearch = true
        }
      }, 500);
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.fshijCN();
          this.materialSellingReport({
            startDate: this.from,
            endDate: this.to,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            clientId: null,
            orderNumber: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByCustomerName(value);
          this.noSearchTwo = true
        }
      }, 500);
    },
    from(newValue) {
      if (newValue && this.to) {
        this.materialSellingReport({
          startDate: newValue,
          endDate: this.to,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          orderNumber: this.searchON ? this.searchON : null,
          clientId: this.clientId ? this.clientId : null,
        });
      }
    },
    to(newValue) {
      if (newValue && this.from) {
        this.materialSellingReport({
          startDate: this.from,
          endDate: newValue,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          orderNumber: this.searchON ? this.searchON : null,
          clientId: this.clientId ? this.clientId : null,
        });
      }
    },
    pageNumber(newValue) {
      this.materialSellingReport({
        startDate: this.from,
        endDate: this.to,
        pageNumber: newValue,
        pageSize: this.pageSize,
        orderNumber: this.searchON ? this.searchON : null,
        clientId: this.clientId ? this.clientId : null,
      });
    },
  },
  mounted() {
    this.materialSellingReport({
      startDate: this.from,
      endDate: this.to,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      clientId: null,
      orderNumber: null,
    })
  },
  methods: {
    ...mapActions(['materialSellingReport', 'fshij', 'fshijCN', 'searchByShipmentCode', 'searchByCustomerName', 'searchByOrderNumber', 'materialsSellingReportPDF']),
    showOrderNumber() {
      this.toggleSarch = 'OrderNumber';
      this.show = false
      this.searchCN = null;
      this.clientID = null;
      this.materialSellingReport({
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clientId: null,
        orderNumber: null,
      })
    },
    showCustomerName() {
      this.toggleSarch = 'CustomerName';
      this.show = true
      this.searchON = null;
      this.materialSellingReport({
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clientId: null,
        orderNumber: null,
      })
    },
    searchByOn(value) {
      this.searchON = value
      this.noSearch = false
      this.materialSellingReport({
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clientId: null,
        orderNumber: this.searchON,
      })
      this.noSearch = false
    },
    searchByCn(value) {
      this.searchCN = value.nameToDisplay
      this.clientId = value.clientId
      this.noSearchTwo = false
      this.materialSellingReport({
        startDate: this.from,
        endDate: this.to,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clientId: value.clientId,
        orderNumber: null,
      })
      this.noSearchTwo = false
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredOrdersbyON[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyON.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 < this.getFilteredOrdersbyCN.length - 1) {
        this.arrowCounter2 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 > 0) {
        this.arrowCounter2 -= 1;
        this.fixScrolling()
      }
    },

    onArrowDown3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 < this.getFilteredOrdersByShipment.length - 1) {
        this.arrowCounter3 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 > 0) {
        this.arrowCounter3 -= 1;
        this.fixScrolling()
      }
    },


    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFilteredOrdersbyCN[this.arrowCounter2])
        this.removeLists()
        this.arrowCounter2 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged3(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByShip(this.getFilteredOrdersByShipment[this.arrowCounter3])
        this.removeLists()
        this.arrowCounter3 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    numbersWithcomma(value) {
      if (value === null) {
        return '0';
      }

      const options = {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return value.toLocaleString('en-US', options);
    },
    generateTableContentCSV(data) {
      const headers = [this.$t('OrderNumber'), this.$t('OrderType'), this.$t('VariantName'), this.$t('MeasurementType'), this.$t('Length'), this.$t('Quantity'), this.$t('PricePerMeter'), this.$t('PricePerMeterWithDiscount'), this.$t('TotalPrice')];
      const body = [];

      data.forEach((item) => {
        item.materialVariantInStoreDtos.forEach((variant) => {
          let totalPrice = 0;
          if (variant.length > 0) {
            totalPrice = variant.pricePerMeterWithDiscount * variant.length;
          } else if (variant.quantity > 0) {
            totalPrice = variant.pricePerMeterWithDiscount * variant.quantity;
          }
          body.push([
            item.orderNumber,
            item.orderType,
            variant.name,
            variant.measurementType,
            variant.length,
            variant.quantity,
            variant.pricePerMeter,
            variant.pricePerMeterWithDiscount,
            totalPrice,
          ]);
        });
      });

      return { headers, body };
    },
    generateExcel(tableContent) {
      const { headers, body } = tableContent;
      const sheetData = [headers, ...body];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const excelData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      return excelData;
    },

    downloadEXCEL() {
      this.materialSellingReport({
        pageNumber: this.pageNumber,
        pageSize: 50,
        startDate: this.from,
        endDate: this.to,
      }).then(() => {
        const tableContent = this.generateTableContentCSV(this.getMaterialSellingReport);

        const totalPages = this.getMaterialSellingReport;
        const additionalPromises = [];
        for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
          additionalPromises.push(this.downloadPage(pageNumber));
        }

        Promise.all(additionalPromises).then((additionalTableContents) => {
          additionalTableContents.forEach((additionalTableContent) => {
            tableContent.body = tableContent.body.concat(additionalTableContent.body);
          });

          const excelData = this.generateExcel(tableContent);
          FileSaver.saveAs(excelData, 'SellingReportDetailed.xlsx');
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pt-0 {
  padding-bottom: 0px !important;
}
.inputs {
  display: flex;
  width: 100%;
  gap: 5px;
  margin-bottom: 1%;
}
.team_table td {
  padding: 16px 9px !important;
}

.buttons {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}
table:hover {
  cursor: pointer;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}
.form-control{
  width: 193px;
  border: none;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.active2 {
  font-weight: bold;
  color: $base-color;
}

@media screen and (min-width: 1340px) and (max-width: 1500px) {
  .main{
    padding: 90px 10px 15px 90px;
  }
}

@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 10px 15px 10px !important;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 90px 10px 15px 90px;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
